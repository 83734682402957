<style lang="postcss">
    .article-content {
        font-family: 'Times New Roman', serif;
        font-size: 16px;
        color: #333;
        line-height: 1.6;
    }

    :global(a) {
        color: #333;
        text-decoration: underline;
    }
    :global(.blog a:hover) {
        color: green;
        text-decoration: underline;
    }
</style>

<script lang="ts">
    import CarouselWrapper from './CarouselWrapper.svelte'
    import { onMount } from 'svelte'
    import { articlesStore, type Article } from './component/stores/blog'
    import { Carousel } from 'flowbite-svelte'
    import type { Image } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'

    export let params: { slug: string }

    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]

    let slug = ''
    let article: Article | undefined = undefined

    onMount(() => {
        slug = params.slug
    })

    $: if (slug) {
        const unsub = articlesStore.subscribe((articles) => {
            article = articles.find((a) => a.slug === slug)
        })
        unsub()
    }

    $: images = article?.imageCollection || []
    $: if (images) console.log('Test image array', images)
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{40}"
    y="{0}"
    class="w-1/4 md:w-auto"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    class="w-1/4 md:w-auto"
    positionX="right"
    positionY="top" />

<main class="blog max-w-[672px] mx-auto mb-72 bg-white shadow-lg p-6 rounded-lg">
    <section class="my-12 max-w-[672px]">
        <IconLeftRigthWrapper>
            <h1 class="font-bold">{article?.title}</h1>
        </IconLeftRigthWrapper>
    </section>
    <section class="my-12 rel">
        <CarouselWrapper className="sm:h-96" images="{images}" layer="{false}" />
    </section>
    <section class="my-12">
        <div class="article-content prose max-w-none text-left text-gray-700">
            {@html article?.content}
        </div>
    </section>
</main>

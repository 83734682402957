<script>
    import QuantitySlider from '../../components/quantitySlider.svelte'
    import { addVariantToCart } from '../../state/cartStore'
    import { medusaProductStore, retrieveProduct } from '../../state/productStore'

    import { formatPrices, getRouteParam } from '../../utils/helpers'
    import { checkoutState, setCheckoutState } from '../../state/store'

    let productData
    let orderCount = 1
    let selectedOptions = {} // Objekt für ausgewählte Optionen
    let selectedVariant = null

    export let params = { slug }

    async function preload() {
        medusaProductStore.subscribe((item) => {
            productData = item.product

            if (item.product) {
                // Initialisiere selectedOptions mit null für jede Option
                productData.options.forEach((option) => {
                    selectedOptions[option.title] = null
                })
                findMatchingVariant()
            }
        })

        await retrieveProduct(params.productId)
    }

    const removeOrder = () => {
        if (orderCount > 1) {
            orderCount = orderCount - 1
        }
    }

    const addOrder = () => {
        orderCount = orderCount + 1
    }

    function findMatchingVariant() {
        if (!productData) {
            selectedVariant = null
            return
        }

        // Überprüfe, ob alle Optionen ausgewählt wurden
        const allOptionsSelected = Object.values(selectedOptions).every((value) => value !== null)

        if (!allOptionsSelected) {
            selectedVariant = null
            return
        }

        selectedVariant = productData.variants.find((variant) => {
            return variant.options.every((variantOption) => {
                const optionTitle = productData.options.find((option) => option.id === variantOption.option_id).title

                const selectedValue = selectedOptions[optionTitle]

                return selectedValue === variantOption.value
            })
        })
    }

    function selectOption(optionTitle, value) {
        selectedOptions[optionTitle] = value
        findMatchingVariant()
    }

    // $: console.log('Produktdaten', JSON.stringify(productData, null, 2))


    function getMetadataPairs(metadata) {
        let metadataPairs = [];
        const entries = Object.entries(metadata);

        // Paare bilden: Jeder ungerade Index ist der Titel, jeder gerade Index die Beschreibung
        for (let i = 0; i < entries.length; i += 2) {
            const title = entries[i] ? entries[i][1] : null;  // Titel
            const description = entries[i + 1] ? entries[i + 1][1] : null;  // Beschreibung
            metadataPairs.push({ title, description });
        }
        
        return metadataPairs;
    }
</script>

<svelte:head>Product</svelte:head>

<div class="mb-[320px] text-left font-sans text-base flex flex-col md:flex-row relative">
    {#await preload() then _}
        {#if !productData}
            <p>Loading product ...</p>
        {:else}
            <!-- Linke Spalte -->
            <div class="md:w-1/4 w-full py-8 gap-y-6">
                <div class="md:sticky md:top-48 flex flex-col gap-y-6">
                    <div>
                        <h2 class="text-base font-semibold font-sans">
                            {productData.title}
                        </h2>
                        <div class="flex gap-2 mt-2">
                            <p class="text-base">{productData.description}</p>
                        </div>
                    </div>
                    {#if productData.material || productData.weight || productData.length || productData.height || productData.width || productData.metadata}
                        <div>Product Info</div>

                        {#if productData.material}
                            <p>Material: {productData.material}</p>
                        {/if}

                        {#if productData.weight}
                            <p>Gewicht: {productData.weight}</p>
                        {/if}

                        {#if productData.length}
                            <p>Länge: {productData.length}</p>
                        {/if}

                        {#if productData.height}
                            <p>Höhe: {productData.height}</p>
                        {/if}

                        {#if productData.width}
                            <p>Breite: {productData.width}</p>
                        {/if}

                        {#if productData?.metadata && Object.keys(productData.metadata).length > 0}
                            <div>Metadata Info</div>
                            {#each getMetadataPairs(productData.metadata) as pair}
                                <p>{pair.title}: {pair.description}</p>
                            {/each}
                        {/if}
                    {/if}
                </div>
            </div>

            <!-- Mittlere Spalte -->
            <div class="md:w-1/2 block w-full md:min-h-[calc(100vh-100px)] relative">
                <div class="md:mx-6">
                    {#each productData.images as image}
                        <div class="mb-4 border border-gray-200 rounded-lg relative overflow-hidden aspect-[29/34] w-full">
                            <img
                                alt="Product image {image.id}"
                                fetchpriority="high"
                                decoding="async"
                                data-nimg="fill"
                                class="absolute inset-0 rounded-rounded"
                                sizes="(max-width: 576px) 280px, (max-width: 768px) 360px, (max-width: 992px) 480px, 800px"
                                srcset="{image.url}?w=16&q=75 16w,
                        {image.url}?w=32&q=75 32w,
                        {image.url}?w=48&q=75 48w,
                        {image.url}?w=64&q=75 64w,
                        {image.url}?w=96&q=75 96w,
                        {image.url}?w=128&q=75 128w,
                        {image.url}?w=256&q=75 256w,
                        {image.url}?w=384&q=75 384w,
                        {image.url}?w=640&q=75 640w,
                        {image.url}?w=750&q=75 750w,
                        {image.url}?w=828&q=75 828w,
                        {image.url}?w=1080&q=75 1080w,
                        {image.url}?w=1200&q=75 1200w,
                        {image.url}?w=1920&q=75 1920w,
                        {image.url}?w=2048&q=75 2048w,
                        {image.url}?w=3840&q=75 3840w"
                                src="{image.url}?w=3840&q=75"
                                style="position: absolute; height: 100%; width: 100%; inset: 0px; object-fit: cover; color: transparent;" />
                        </div>
                    {/each}
                </div>
            </div>

            <!-- Rechte Spalte -->
            <div class="md:w-1/4 w-full py-8 gap-y-6">
                <div class="md:sticky md:top-48 flex flex-col gap-y-6">
                    <!-- Optionen auswählen -->
                    {#each productData.options as option}
                        <div>
                            <h2 class="font-semibold text-base font-sans">Select {option.title}</h2>
                            <div class="flex gap-2 mt-2">
                                {#each [...new Set(option.values.map((v) => v.value))] as value}
                                    <div
                                        class="option-box cursor-pointer border p-2"
                                        role="button"
                                        tabindex="0"
                                        on:click="{() => selectOption(option.title, value)}"
                                        on:keydown="{(event) => {
                                            if (event.key === 'Enter' || event.key === ' ') {
                                                event.preventDefault()
                                                selectOption(option.title, value)
                                            }
                                        }}"
                                        style="background-color: {selectedOptions[option.title] === value ? 'lightgrey' : 'white'};">
                                        {value}
                                    </div>
                                {/each}
                            </div>
                        </div>
                    {/each}

                    <!-- Menge auswählen -->
                    <div class="mb-5">
                        <h2 class="font-semibold text-base font-sans">Menge auswählen</h2>
                        <QuantitySlider orderCount="{orderCount}" increaseQuantity="{() => addOrder()}" decreaseQuantity="{() => removeOrder()}" />
                    </div>

                    <!-- Preis und In den Warenkorb Button -->
                    <div class="mb-7">
                        <p class="text-base font-semibold font-sans">
                            {#if selectedVariant}
                                {formatPrices(productData, selectedVariant)}
                            {:else}
                                Bitte wähle Größe und Farbe aus
                            {/if}
                        </p>
                        <button
                            class="custom-btn {!selectedVariant && 'opacity-50 cursor-not-allowed'}"
                            on:click="{() => {
                                if (selectedVariant) {
                                    addVariantToCart(selectedVariant.id, orderCount)
                                    orderCount = 1
                                    setCheckoutState(true)
                                } else {
                                    alert('Bitte wähle Größe und Farbe aus.')
                                }
                            }}"
                            disabled="{!selectedVariant}">
                            In den Warenkorb
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        {/if}
    {/await}
</div>

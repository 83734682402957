<script lang="ts">
    import { Button, Carousel, Heading } from 'flowbite-svelte'
    import { articlesStore, type Article } from './component/stores/blog'
    import Slide from './Slide.svelte'
    import { push, pop, replace } from 'svelte-spa-router'
    import FullscreenImage from './component/FullscreenImage.svelte'
    export let images: any = []
    export let className = ''
    export let layer = true
    const btn2 = () => {
        push('/page')
    }

    let index: number = 0

    $: articles = $articlesStore
    $: images = articles?.map((article) => article?.image)

    let isFullscreen = false
</script>

<div class="max-w-4xl relative">
    <Carousel images="{images}" duration="{isFullscreen ? 0 : 0}" bind:index="{index}">
        <div slot="slide" class="relative">
            <button
                on:click="{() => (isFullscreen = true)}"
                on:keydown="{(e) => {
                    if (e.key === 'Enter') isFullscreen = true
                }}"
                class="h-full w-full md:h-auto md:w-full cursor-pointer">
                <Slide image="{images[index]}" class="{className}">
                    {#if layer}
                        <div class="flex flex-wrap gap-2 absolute justify-center items-center left-0 right-0 top-0 bottom-0">
                            <span class="bg-white bg-opacity-75 py-6 px-12 flex flex-col justify-center items-center">
                                <Heading color="text-base text-gray-800" tag="h2" class="mb-4 text-shadow">{articles[index].title}</Heading>
                                <Button href="#/blog/{articles[index].slug}" color="green" on:click="{() => push(`/blog/${articles[index].slug}`)}">Zum Artikel</Button>
                            </span>
                        </div>
                    {/if}
                </Slide>
            </button>
        </div>
    </Carousel>

    {#if isFullscreen}
        <FullscreenImage imageSrc="{images[index].src}" altText="{images[index].alt}" on:close="{() => (isFullscreen = false)}" />
    {/if}
</div>

<style>
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info-message {
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: 8px;
        margin-top: 2rem;
        font-size: 1rem;
        color: #333;
    }
</style>

<script>
    import { onMount } from 'svelte';
    import OrderItemPreview from '../../components/orderItemPreview.svelte';
    import { completeCartCheckout, medusaCartConfirmation, resetCart } from '../../state/cartStore';
    import '../../styles/component.css';
    import { formatPrice } from '../../utils/helpers';

    let loadingPayment = true;
    let cartConfirmation = {};

    onMount(async () => {
        await completeCartCheckout();
    });

    medusaCartConfirmation.subscribe((state) => {
        if (state.email) {
            cartConfirmation = state;
            loadingPayment = false;
        }
    });
</script>

<svelte:head>Zahlung</svelte:head>

<div class="mb-[320px] text-left">
    {#if loadingPayment}
        <div class="flex justify-center">
            <p>Bitte warten Sie, während wir Ihre Zahlung überprüfen...</p>
        </div>
    {:else}
        <div class="container">
            <div>
                <div class="my-5">
                    <h1 class="font-semibold text-4xl mb-5">Bestellübersicht</h1>
                    <p class="mb-5">Vielen Dank für Ihre Bestellung!</p>
                </div>

                <hr />

                {#each cartConfirmation.items as { thumbnail, title, quantity, unit_price, variant }}
                    <div class="my-5">
                        <OrderItemPreview orderThumbnail="{thumbnail}" name="{title}," size="{variant.title}" quantity price="{formatPrice(unit_price, cartConfirmation?.region?.currency_code)}" />
                    </div>
                {/each}

                <hr />

                <div class="my-5">
                    <div class="flex justify-between mb-3">
                        <p>Zwischensumme</p>
                        <p>{formatPrice(cartConfirmation.subtotal, cartConfirmation?.region?.currency_code)}</p>
                    </div>

                    <div class="flex justify-between mb-3">
                        <p>Versand</p>
                        <p>{formatPrice(cartConfirmation.shipping_total, cartConfirmation?.region?.currency_code)}</p>
                    </div>

                    <div class="flex justify-between font-semibold mb-3">
                        <p>Gesamt</p>
                        <p>{formatPrice(cartConfirmation.total, cartConfirmation?.region?.currency_code)}</p>
                    </div>
                </div>
                <hr />

                <p class="my-5">
                    Eine Bestellbestätigung wird Ihnen an <b>{cartConfirmation.email}</b> gesendet.
                </p>

                <!-- Hinweis für die manuelle Bestellbestätigung -->
                <div class="info-message">
                    <p>
                        Die automatische Bestellverarbeitung ist derzeit deaktiviert. Sie erhalten innerhalb der nächsten 24 Stunden eine Bestätigung Ihrer Bestellung sowie eine Auswahl an Bezahlmöglichkeiten, einschließlich unserer Bankverbindung und möglicher Termine für die Abholung.
                    </p>
                </div>

                <button class="custom-btn" on:click="{() => resetCart()}" style="width: 100%"> Okay, vorhandene Produkte ansehen </button>
            </div>
        </div>
    {/if}
</div>

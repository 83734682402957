<script lang="ts">
    import type { HTMLImgAttributes } from 'svelte/elements'
    import { fly, type TransitionConfig } from 'svelte/transition'
    import { twMerge } from 'tailwind-merge'
    import { getContext } from 'svelte'
    import type { Writable } from 'svelte/store'
    import type State from 'flowbite-svelte/Slide.svelte'

    const state = getContext<Writable<State>>('state')

    type TransitionFunc = (node: HTMLElement, params: any) => TransitionConfig

    export let image: HTMLImgAttributes
    export let transition: TransitionFunc | null = null // Optional transition function, overrides default slide transition

    $: transitionSlideIn = {
        x: $state.forward ? '100%' : '-100%',
        opacity: 1,
        width: '100%',
        height: '100%',
        duration: $state.slideDuration
    }

    $: transitionSlideOut = {
        x: $state.forward ? '-100%' : '100%',
        opacity: 0.9,
        width: '100%',
        height: '100%',
        duration: $state.slideDuration
    }

    $: imgClass = twMerge('relative md:absolute block !w-full h-full object-cover', $$props.class)
</script>

{#if transition}
    {#key image}
        <div transition:transition="{{}}" class="{imgClass}">
            <img alt="..." {...image} {...$$restProps} class="absolute h-full w-auto min-[600px]:h-auto min-[600px]:w-full max-w-none" />
            <slot />
        </div>
    {/key}
{:else}
    {#key image}
        <div out:fly="{transitionSlideOut}" in:fly="{transitionSlideIn}" class="{imgClass}">
            <img alt="..." {...image} {...$$restProps} class="absolute h-full w-auto min-[600px]:h-auto min-[600px]:w-full max-w-none" />
            <slot />
        </div>
    {/key}
{/if}

<script lang="ts">
    import type { Image } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    import BlogEntries from './component/BlogEntries.svelte'
    import { Section } from 'flowbite-svelte-blocks'
    import { BlogHead } from 'flowbite-svelte-blocks'
    import { BlogBodyWrapper } from 'flowbite-svelte-blocks'

    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{20}"
    y="{0}"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    positionX="right"
    positionY="top" />

<main class="blog max-w-[672px] m-auto mb-96">
    <Section name="blog" sectionClass="my-12 max-w-[672px]">
        <IconLeftRigthWrapper>
            <BlogHead>
                <svelte:fragment slot="h2">
                    <span class="text-2xl font-bold text-center mb-4">Imker Insights: Tipps</span>
                </svelte:fragment>
                <svelte:fragment slot="paragraph">
                    <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Tutorials & Mehr</p>
                </svelte:fragment>
            </BlogHead>
        </IconLeftRigthWrapper>
        <BlogBodyWrapper>
            <BlogEntries />
        </BlogBodyWrapper>
    </Section>
</main>

import { writable } from 'svelte/store'

export const checkoutStore = writable({
    currentCheckoutStep: 'Information',
    hasSelectedShipping: false
})

type CheckoutStep = 'Cart' | 'Delivery' | 'Payment' | 'Confirmation'

export const updateCheckoutStore = ({ currentStep, hasSelectedShipping }: { currentStep: CheckoutStep; hasSelectedShipping: boolean }) => {
    checkoutStore.update((state) => {
        state.currentCheckoutStep = currentStep || state.currentCheckoutStep
        state.hasSelectedShipping = hasSelectedShipping || state.hasSelectedShipping

        return state
    })
}

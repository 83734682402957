<style>
    .step-ctn {
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        box-shadow: 0 2px 3px #c4c4c4;
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }
</style>

<script>
    import { onMount } from 'svelte'

    import { medusaCartState } from '../../state/cartStore'
    import { checkoutStore, updateCheckoutStore } from '../../state/checkoutStore'
    import { getShippingOptions, medusaShipping, setShippingMethod } from '../../state/productStore'

    let shippingOptions = []
    let selectedShippingOption
    let shippingLoader = true
    let userShippingAddress = []
    let hasSelectedShipping

    onMount(async () => await getShippingOptions())

    medusaShipping.subscribe((state) => {
        shippingOptions = state

        if (state.length > 0 && !selectedShippingOption) {
            // Wählt die erste verfügbare Versandoption aus
            selectedShippingOption = state[0]
            setShippingMethod(state[0].id)
        }
    })

    let medusaCartStateX = {}
    medusaCartState.subscribe((state) => {
        medusaCartStateX = state
        if (state.cart.shipping_address) {
            userShippingAddress = state.cart.shipping_address
            shippingLoader = false
        }
    })

    const setDeliveryOption = async (option) => {
        try {
            if (selectedShippingOption.id === option.id) {
                return
            }

            await setShippingMethod(option.id)
            selectedShippingOption = option

            updateCheckoutStore({
                hasSelectedShipping: true,
                currentStep: 'Payment'
            })
        } catch (e) {
            console.log(e)
        }
    }
</script>

<div>
    {#if shippingLoader}
        <div>
            <p>Lade Schritte ....</p>
        </div>
    {:else}
        <!-- <pre>
    {JSON.stringify(medusaCartStateX, null, 2)}
  </pre> -->
        <div class="mb-5 mt-5">
            <div class="step-ctn">
                <div class="flex flex-col justify-between" style="width: 100%;">
                    <p class="font-bold">Kontakt</p>
                    <p>
                        {userShippingAddress.first_name}{' '}{userShippingAddress.last_name}
                    </p>
                    <div
                        class="cursor-pointer"
                        role="button"
                        tabindex="0"
                        on:keydown="{(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                event.preventDefault()
                                updateCheckoutStore({ currentStep: 'Information' })
                            }
                        }}"
                        on:click="{() => updateCheckoutStore({ currentStep: 'Information' })}">
                        Bearbeiten
                    </div>
                </div>
            </div>

            <div class="step-ctn">
                <div class="flex flex-col justify-between" style="width: 100%;">
                    <p class="font-bold">Adresse</p>
                    <p>
                        {userShippingAddress.address_1}, {userShippingAddress.postal_code}
                        {userShippingAddress.city}
                    </p>
                    <div
                        class="cursor-pointer"
                        role="button"
                        tabindex="0"
                        on:keydown="{(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                event.preventDefault()
                                updateCheckoutStore({ currentStep: 'Information' })
                            }
                        }}"
                        on:click="{() => updateCheckoutStore({ currentStep: 'Information' })}">
                        Bearbeiten
                    </div>
                </div>
            </div>
        </div>

        <h2 class="font-semibold text-2xl mb-5 mt-5">Versand</h2>

        <div class="mb-5 mt-5">
            {#each shippingOptions as option}
                <div
                    role="button"
                    tabindex="0"
                    on:keydown="{(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            event.preventDefault()
                            setDeliveryOption(option)
                        }
                    }}"
                    on:click="{() => setDeliveryOption(option)}"
                    class="step-ctn cursor-pointer"
                    style="{`width: 100%; border: ${selectedShippingOption?.id === option?.id && '2px solid #383e46'}`}">
                    <div class="flex justify-between" style="width: 100%;">
                        <p>{option.name}</p>
                    </div>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style>
    .full-image {
        object-fit: contain; /* Stellt sicher, dass das Bild komplett sichtbar ist und das Seitenverhältnis beibehält */
    }
</style>

<script lang="ts">
    import { createEventDispatcher } from 'svelte'

    export let imageSrc: string | undefined | null = ''
    export let altText: string | undefined | null = ''

    const dispatch = createEventDispatcher()

    function close() {
        dispatch('close')
    }
</script>

<!-- Vollbild-Overlay für das Bild -->
{#if imageSrc}
    <div
        class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        on:click="{close}"
        on:keydown="{(e) => {
            if (e.key === 'Enter' || e.key === 'Escape') close()
        }}"
        tabindex="0"
        role="button"
        aria-label="Close fullscreen image">
        <img src="{imageSrc}" alt="{altText}" class="max-w-full min-h-[90vh] max-h-full full-image" />
    </div>
{/if}

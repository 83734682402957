<script lang="ts">
    import type { Image, Product } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{20}"
    y="{0}"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    positionX="right"
    positionY="top" />

    <main class="max-w-[672px] m-auto mb-96">
        <section class="my-12 max-w-[672px]">
            <IconLeftRigthWrapper>
                <h1 class="text-2xl font-bold text-center mb-4">Widerrufsbelehrung</h1>
            </IconLeftRigthWrapper>
    
            <div class="shadow-md rounded-lg p-6 mt-12 mx-4 md:mx-28">
                <h2 class="text-xl font-semibold mb-2">1. Widerrufsrecht</h2>
                <p class="mb-4">
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Dieses Widerrufsrecht gilt jedoch nicht für alle Waren (siehe Ausnahmen unten).
                </p>
                <p class="mb-4">
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">2. Ausnahmen vom Widerrufsrecht</h2>
                <p class="mb-4">
                    Das Widerrufsrecht besteht nicht bei Verträgen über die Lieferung von:
                </p>
                <ul class="mb-4">
                    <li>Lebensmitteln oder anderen Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde,</li>
                    <li>versiegelten Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde.</li>
                </ul>
    
                <h2 class="text-xl font-semibold mb-2">3. Ausübung des Widerrufsrechts</h2>
                <p class="mb-4">
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Alexander Feier, Auf den Stöcken 52, 41472 Neuss, E-Mail: info@specker-bienchen.de) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
                </p>
                <p class="mb-4">
                    Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">4. Folgen des Widerrufs</h2>
                <p class="mb-4">
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
                </p>
                <p class="mb-4">
                    Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                </p>
                <p class="mb-4">
                    Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
                </p>
                <p class="mb-4">
                    Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
                </p>
                <p class="mb-4">
                    Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
                </p>
                <p class="mb-4">
                    Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">5. Gewährleistung bei Mängeln</h2>
                <p class="mb-4">
                    Sollte eine gelieferte Ware mangelhaft sein, gilt das gesetzliche Mängelhaftungsrecht. Dieses gilt jedoch nicht für verderbliche Lebensmittel. Nicht-lebensmittelbezogene Produkte werden bei Mängeln nach den gesetzlichen Bestimmungen ersetzt oder erstattet.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">6. Muster-Widerrufsformular</h2>
                <p class="mb-4">
                    Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden es zurück:
                </p>
                <p class="mb-4">
                    <strong>An:</strong>  
                    Alexander Feier<br>
                    Auf den Stöcken 52<br>
                    41472 Neuss<br>
                    E-Mail: info@specker-bienchen.de
                </p>
                <p class="mb-4">
                    <strong>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)</strong>  
                </p>
                <p class="mb-4">
                    Bestellt am (*)/erhalten am (*):  
                    <br>
                    Name des/der Verbraucher(s):  
                    <br>
                    Anschrift des/der Verbraucher(s):  
                </p>
                <p class="mb-4">Datum und Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
                <p class="mb-4">(*) Unzutreffendes streichen.</p>
            </div>
        </section>
    </main>
    
    
    
    
<script lang="ts">
    import type { Image, Product } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{20}"
    y="{0}"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    positionX="right"
    positionY="top" />

<main class="max-w-[672px] m-auto mb-96">
    <section class="my-12 max-w-[672px]">
        <IconLeftRigthWrapper>
            <h1 class="text-2xl font-bold text-center mb-4">Datenschutzerklärung</h1>
        </IconLeftRigthWrapper>

        <div class="shadow-md rounded-lg p-6 mt-12 mx-4 md:mx-28">
            <h2 class="text-xl font-semibold mb-2">1. Datenschutz auf einen Blick</h2>
            <p class="mb-4">
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle
                Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unten aufgeführten Datenschutzerklärung.
            </p>

            <h2 class="text-xl font-semibold mb-2">2. Datenerfassung auf unserer Website</h2>

            <p class="mb-4">
                <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong><br />
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber:
            </p>
            <p class="mb-4">
                <strong>Inhaber:</strong> Alexander Feier<br />
                <strong>Adresse:</strong> Auf den Stöcken 52, 41472 Neuss<br />
                <strong>Website:</strong> <a href="http://www.specker-bienchen.de" class="text-blue-600 hover:text-blue-800 visited:text-purple-600">www.specker-bienchen.de</a>
            </p>

            <p class="mb-4">
                <strong>Wie erfassen wir Ihre Daten?</strong><br />
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch
                beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
                Daten erfolgt automatisch, sobald Sie unsere Website betreten.
            </p>

            <p class="mb-4">
                <strong>Wofür nutzen wir Ihre Daten?</strong><br />
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>

            <h2 class="text-xl font-semibold mb-2">3. Ihre Rechte</h2>
            <p class="mb-4">
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
                Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
            </p>

            <h2 class="text-xl font-semibold mb-2">4. Allgemeine Hinweise und Pflichtinformationen</h2>
            <p class="mb-4">
                Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Ihre personenbezogenen Daten werden vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
                dieser Datenschutzerklärung behandelt.
            </p>
            <p class="mb-4">
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Diese
                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
            </p>

            <h2 class="text-xl font-semibold mb-2">5. Hosting und Server-Log-Dateien</h2>
            <p class="mb-4">Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
            <ul class="mb-4">
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                <li>IP-Adresse</li>
            </ul>
            <p class="mb-4">Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>

            <h2 class="text-xl font-semibold mb-2">6. Cookies</h2>
            <p class="mb-4">
                Unsere Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
                nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            </p>

            <h2 class="text-xl font-semibold mb-2">7. Nutzung von Stripe als Zahlungsdienstleister</h2>
            <p class="mb-4">
                Wir nutzen auf unserer Website den Zahlungsdienstleister Stripe für die Abwicklung von Zahlungen. Wenn Sie auf unserer Website einen Kauf tätigen, werden personenbezogene Daten (z. B.
                Name, Rechnungsanschrift, E-Mail-Adresse, Zahlungsinformationen) an Stripe übermittelt.
            </p>
            <p class="mb-4">
                Stripe ist ein Dienst von Stripe Inc., 510 Townsend Street, San Francisco, CA 94103, USA. Stripe hat sich zur Einhaltung der DSGVO verpflichtet und bietet durch spezielle
                Vereinbarungen den Schutz personenbezogener Daten. Nähere Informationen zum Datenschutz bei Stripe finden Sie unter: <a
                    href="https://stripe.com/de/privacy"
                    class="text-blue-600 hover:text-blue-800 visited:text-purple-600">https://stripe.com/de/privacy</a
                >.
            </p>

            <h2 class="text-xl font-semibold mb-2">8. Datensicherheit</h2>
            <p class="mb-4">
                Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre durch uns verwalteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder
                gegen den Zugriff unberechtigter Personen zu schützen.
            </p>
        </div>
    </section>
</main>

<style>
    .controls {
        color: darkgrey;
        transition: all 250ms;
        font-size: 1.1em;
    }

    .controls:hover {
        cursor: pointer;
        color: #000;
    }
</style>

<script>
    export let increaseQuantity = function () {}
    export let decreaseQuantity = function () {}
    export let orderCount
</script>

<div class="inline-block bg-white border rounde-[5px] min-w-[120px] h-12">
    <div class="flex items-center h-full">
        <button class="controls cursor-pointer px-4 text-xl" on:click="{() => decreaseQuantity()}"> - </button>
        <div class="flex-1 text-center text-xl">
            {orderCount}
        </div>
        <button class="controls cursor-pointer px-4 text-xl" on:click="{() => increaseQuantity()}"> + </button>
    </div>
</div>

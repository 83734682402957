<style>
    .left-images {
        left: -3px;
    }

    .right-images {
        right: -3px;
    }

    .image {
        width: 50px; /* Breite der Bilder */
        height: 50px; /* Höhe der Bilder */
        object-fit: cover;
        position: absolute; /* Positionierung jedes Bildes wird absolut sein */
    }
    @media (min-width: 1024px) {
        .left-images {
            left: -25px;
        }

        .right-images {
            right: -25px;
        }
        .image {
            width: 100px; /* Breite der Bilder */
            height: 100px; /* Höhe der Bilder */
            object-fit: cover;
            position: absolute; /* Positionierung jedes Bildes wird absolut sein */
        }
    }
</style>

<script lang="ts">
    import { onMount } from 'svelte'
    import type { Image } from '../../types'
    export let leftImages: Image[] = []
    export let rightImages: Image[] = []
    export let faktor = 476 // Der Faktor bestimmt die vertikale Verteilung der Bilder

    let displayedLeftImages: Image[] = []
    let displayedRightImages: Image[] = []
    let numberOfImages

    const selectRandomImages = (list: Image[], count: number) => {
        const shuffled = [...list].sort(() => 0.5 - Math.random())
        return shuffled.slice(0, count)
    }

    const calculateImagePositions = (count: number) => {
        /// count 1,2,3
        /// faktor 476 / 2 230
        //*1
        let top = 100
        if (count === 0) {
            return top
        }
        let pos = top + count * faktor - 50
        return pos
    }

    onMount(() => {
        const windowHeight = window.innerHeight
        numberOfImages = Math.ceil(windowHeight / faktor)

        displayedLeftImages = selectRandomImages(leftImages, numberOfImages)
        displayedRightImages = selectRandomImages(rightImages, numberOfImages)
    })

    // Positionen der Bilder berechnen
</script>

{#each displayedLeftImages as image, index (image)}
    <img class="image left-images" src="{image.url}" alt="{image.description}" style="top: {calculateImagePositions(index)}px;" />
{/each}

{#each displayedRightImages as image, index (image)}
    <img class="image right-images" src="{image.url}" alt="{image.description}" style="top: {calculateImagePositions(index)}px;" />
{/each}

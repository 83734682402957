<script lang="ts">
    interface AccessLog {
        id: number
        ip_address: string
        path: string
        access_count: number
        last_access: string // ISO Date string
    }

    interface DailyVisit {
        id: number
        ip_address: string
        path: string
        visit_date: string // ISO Date string
    }

    interface PathCount {
        path: string
        total_access_count: string
    }

    interface UniqueIpCount {
        visit_date: string
        unique_ips: string
    }

    interface LogData {
        accessLogs?: AccessLog[]
        dailyVisits?: DailyVisit[]
        pathCounts?: PathCount[]
        uniqueIpCounts?: UniqueIpCount[]
    }
    import { Button, Listgroup, ListgroupItem } from 'flowbite-svelte'
    import { Section } from 'flowbite-svelte-blocks'

    let username = ''
    let password = ''
    let jsonData: LogData = {}
    let auth = ''
    let isLogin = false
    let totalUniqueIPs: number = 0

    function login() {
        auth = btoa(username + ':' + password)
        fetchData()
    }

    function fetchData() {
        fetch('/api/logs', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Basic ${auth}`
            }
        })
            .then((response) => {
                if (response.ok) return response.json()
                throw new Error('Failed to fetch data: ' + response.status)
            })
            .then((data) => {
                console.log(data)
                jsonData = data
                let countUniqueIPs = 0
                jsonData?.uniqueIpCounts?.forEach((element) => {
                    countUniqueIPs = countUniqueIPs + parseInt(element.unique_ips)
                })
                totalUniqueIPs = countUniqueIPs
                isLogin = true
            })
            .catch((error) => {
                console.error(error)
            })
    }

    function refreshData() {
        fetchData()
    }

    const formatDate = (dateString: string): string => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }
</script>

<main class="max-w-[672px] m-auto mb-96">
    <Section sectionClass="my-12 max-w-[672px]">
        {#if !isLogin}
            <form on:submit|preventDefault="{login}" class="space-y-4 p-4 max-w-md mx-auto">
                <div>
                    <label for="username" class="block mb-2 text-sm font-medium text-gray-900">Username</label>
                    <input
                        id="username"
                        type="text"
                        bind:value="{username}"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required />
                </div>
                <div>
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                    <input
                        id="password"
                        type="password"
                        bind:value="{password}"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required />
                </div>
                <Button type="submit" class="w-full">Login</Button>
            </form>
        {:else}
            <Button on:click="{refreshData}" class="mt-4">Refresh Data</Button>
        {/if}
    </Section>

    {#if jsonData.accessLogs}
        <Section sectionClass="my-12 max-w-[672px]">
            <h2 class="text-xl font-bold mb-3">Access Logs</h2>
            {#each jsonData.accessLogs as log (log.id)}
                <Listgroup>
                    <ListgroupItem>
                        <div>
                            IP Address: {log.ip_address}
                        </div>
                        <div>
                            Path: {log.path}
                        </div>
                        <div>
                            Access Count: {log.access_count}
                        </div>
                        <div>
                            Last Access: {formatDate(log.last_access)}
                        </div>
                    </ListgroupItem>
                </Listgroup>
            {/each}
        </Section>
    {/if}

    {#if jsonData.uniqueIpCounts && jsonData.uniqueIpCounts.length > 0}
        <Section sectionClass="my-12 max-w-[672px]">
            <h2 class="text-xl font-bold mb-3">Unique IP Counts</h2>
            <Listgroup>
                <ListgroupItem>
                    <div>All Visitors since 20.04.2024: {totalUniqueIPs}</div>
                </ListgroupItem>
            </Listgroup>
            {#each jsonData.uniqueIpCounts as log}
                <Listgroup>
                    <ListgroupItem>
                        <div>All Visitor on Date: {formatDate(log.visit_date)}</div>
                        <div>Count: {log.unique_ips}</div>
                    </ListgroupItem>
                </Listgroup>
            {/each}
        </Section>
    {/if}
</main>

<script>
    import Nav from '../components/Nav.svelte'
    import AllProducts from './product/allProducts.svelte'
</script>

<svelte:head>
    <title>Specker Bienchen Shop</title>
</svelte:head>

<!-- <Nav /> -->
<div class="mb-[320px] max-w-[90%] mx-auto">
    <h1 class="mb-5 text-3xl font-semibold">Specker Bienen Shop</h1>
    <p>Kaufe Honig und Bienenwachsprodukte von Specker Bienen</p>

    <AllProducts />
</div>

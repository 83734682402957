<style>
    .item-added-message {
        background-color: #d4edda;
        color: #155724;
        padding: 1rem;
        margin-bottom: 1rem;
        border: 1px solid #c3e6cb;
        border-radius: 4px;
        text-align: center;
    }
    .center {
        text-align: center;
    }

    .menu-nav {
        display: flex;
        box-shadow: -3px 0 10px 1px rgba(0, 0, 0, 0.1);
        flex-direction: column;
        bottom: 0;
        padding: 1rem;
        transition: all 350ms;
        --py: 35px;
        position: absolute;
        min-width: 460px;
        height: calc(100vh - 72px);
        height: calc(100vh - 72px);
        overflow: hidden;
        justify-content: space-between;
        right: -460px;
        top: 0;
        transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
        -webkit-transform: translateX(110%);
        -ms-transform: translateX(110%);
        transform: translateX(110%);
    }

    .cart-image {
        object-fit: contain;
        height: 200px;
    }

    .list {
        margin: 0;
        list-style: none;
        flex-grow: 1;
        overflow-y: scroll;
    }

    .list::-webkit-scrollbar {
        width: 12px;
        border-radius: 12px;
    }

    .list::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 12px;
    }

    .list::-webkit-scrollbar-thumb {
        border-radius: 20px;
    }

    .custom-btn {
        height: 45px;
        border-radius: 8px;
        font-size: 1rem;
        background: #383e46;
        color: #fff;
        text-align: center;
        border: 1px solid #383e46;
    }

    .custom-btn:hover {
        cursor: pointer;
    }

    .hover {
        cursor: pointer;
    }

    ul {
        list-style: none;
    }

    .sub-text {
        font-size: 0.9rem;
        color: #454b54;
        text-transform: capitalize;
    }

    .remove-btn {
        font-size: 0.9rem;
        color: #454b54;
        text-decoration: underline;
        transition: all 150ms;
    }

    .remove-btn:hover {
        color: #000;
        cursor: pointer;
    }

    @media (max-width: 600px) {
        .menu-nav {
            width: 100%;
            min-width: 100%;
        }
    }
</style>

<script>
    import { deleteVariantFromCart, medusaCartState } from '../state/cartStore'
    import { checkoutState, setCheckoutState } from '../state/store'
    import { formatPrice, quantity, sum } from '../utils/helpers'

    let isLoading = true
    let userCart = []
    let checkoutVisibility

    let itemAddedMessage = false
    let prevTotalQuantity = 0

    checkoutState.subscribe((state) => {
        checkoutVisibility = state
    })

    medusaCartState.subscribe((state) => {
        if (state.cart.items) {
            const totalQuantity = state.cart.items.map(quantity).reduce(sum, 0)
            if (prevTotalQuantity < totalQuantity) {
                itemAddedMessage = true
                setTimeout(() => {
                    itemAddedMessage = false
                }, 3000)
            }
            prevTotalQuantity = totalQuantity

            userCart = state.cart
            isLoading = false
        }
    })

    function handleMouseEnter() {
        // Optional: Aktion ausführen, wenn die Maus über das nav-Element kommt
    }

    function handleMouseLeave() {
        setCheckoutState(false)
    }
</script>

<nav
    class="font-sans text-base menu-nav mt-[74px] z-[1] bg-[#FAF9E5]"
    style="{`transform: ${checkoutVisibility ? 'translateX(-460px)' : 'translateX(110%)'}`}"
    on:mouseenter="{handleMouseEnter}"
    on:mouseleave="{handleMouseLeave}">
    {#if isLoading}
        <div>
            <p>Laden ...</p>
        </div>
    {:else}
        {#if itemAddedMessage}
            <div class="item-added-message">
                <p>Erfolgreich hinzugefügt</p>
            </div>
        {/if}
        <div class="flex justify-between items-center mt-4 mb-6">
            <div><h2>Warenkorb</h2></div>
            <div>
                <p>
                    {userCart.items.length > 0 ? userCart.items.map(quantity).reduce(sum) : 0}{' '}
                    {userCart.items.length > 0 && userCart.items.map(quantity).reduce(sum) === 1 ? 'Artikel' : 'Artikel'}
                </p>
            </div>
            <div
                class="hover"
                role="button"
                tabindex="0"
                on:click="{() => setCheckoutState(false)}"
                on:keydown="{(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault()
                        setCheckoutState(false)
                    }
                }}">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                </svg>
            </div>
        </div>

        <ul class="space-y-4 text-left overflow-scroll">
            {#if userCart.items.length < 1}
                <div>
                    <p class="center">Warenkorb leer</p>
                    <p class="sub-text" style="text-align: center">Ihr Warenkorb ist derzeit leer</p>
                </div>
            {:else}
                {#each userCart.items as { id, thumbnail, variant, unit_price, quantity, title }, index}
                    <li>
                        <div class="product-ctn">
                            <div style="display: flex">
                                <img class="cart-image" alt="{`Warenkorb ${title}`}" src="{thumbnail}" />

                                <ul class="ml-7">
                                    <li>
                                        <p>{title}</p>
                                    </li>
                                    <li>
                                        <p class="sub-text">Größe: {variant.title}</p>
                                    </li>
                                    <li>
                                        <p class="sub-text">
                                            Preis: {formatPrice(unit_price, userCart.region.currency_code)}
                                        </p>
                                    </li>

                                    <li>
                                        <p class="sub-text">Menge: {quantity}</p>
                                    </li>

                                    <li>
                                        <div
                                            role="button"
                                            tabindex="0"
                                            on:click="{() => deleteVariantFromCart(id)}"
                                            on:keydown="{(event) => {
                                                if (event.key === 'Enter' || event.key === ' ') {
                                                    event.preventDefault()
                                                    deleteVariantFromCart(id)
                                                }
                                            }}"
                                            class="remove-btn">
                                            Entfernen
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                {/each}
            {/if}
        </ul>

        <ul class="sticky bottom-[-16px] bg-[#fef3d0] -mx-4 p-6 border">
            <li class="flex justify-between">
                <p>Zwischensumme (inkl. Steuern)</p>
                <p>{formatPrice(userCart.subtotal, userCart.region?.currency_code)}</p>
            </li>

            {#if userCart.items.length > 0}
                <li>
                    <a href="#/checkout" on:click="{() => setCheckoutState(false)}">
                        <button class="custom-btn"> Zur Kasse </button>
                    </a>
                </li>
            {/if}
        </ul>
    {/if}
</nav>

<style>
    .wrapper {
        width: 300px;
    }
    .step-ctn {
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        box-shadow: 0 2px 3px #c4c4c4;
        padding: 1rem;
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    .custom-btn {
        background-color: #007bff;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .custom-btn:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
</style>

<script>
    import { onMount } from 'svelte';

    import { CardCvc, CardExpiry, CardNumber, Container, Elements } from 'svelte-stripe';
    import { startPaymentSession } from '../state/productStore';
    import getStripe from '../utils/stripe';

    let stripe = null;
    let cardElement;
    let paymentError;
    let paymentStatus;

    // Checkboxen für AGB und Datenschutz
    let agbsAccepted = false;
    let privacyAccepted = false;

    // Kontrolliert, ob beide Checkboxen akzeptiert wurden
    let isPaymentEnabled = false;

    onMount(async () => {
        await startPaymentSession();
        stripe = await getStripe();
    });

    async function submit() {
        paymentStatus = 'IN_PROGRESS';

        try {
            if (session.data?.client) {
                const payload = await stripe.confirmCardPayment(session.data.client_secret, {
                    payment_method: {
                        card: cardElement
                    }
                });

                if (payload.error) {
                    paymentError = payload.error.message;
                    paymentStatus = 'FAILED';
                } else {
                    paymentStatus = 'SUCCESS';

                    if (typeof window !== 'undefined') {
                        window.location.href = '/#/payment';
                    }
                }
            } else {
                paymentStatus = 'FAILED';
                paymentError = 'An Error Occurred. Unable to process your payment at the moment.';
            }
        } catch (e) {
            paymentStatus = 'FAILED';
            console.log(e);
        }
    }

    // Aktualisiert den Zustand, ob der Zahlungsbutton aktiviert ist
    $: isPaymentEnabled = agbsAccepted && privacyAccepted;
</script>

<div>
    {#if !stripe}
        <p>Loading Stripe ...</p>
    {:else}
        <Container stripe="{stripe}">
            <form id="payment-form" on:submit|preventDefault="{submit}">

                <div class="bg-[#f1f1f1] border border-gray-300 rounded-lg px-6 shadow-lg mx-auto">
                    <div class="mb-4">
                        <h1 class="text-lg font-semibold mt-4 mb-2">Card Details</h1>
                    </div>

                    <div class="mb-3 bg-white py-4 px-2 rounded-md">
                        <CardNumber bind:element="{cardElement}" class="bg-white border border-gray-400 rounded-md p-2 w-full" />
                    </div>

                    <div class="mb-3 bg-white py-4 px-2 rounded-md">
                        <CardExpiry class="bg-white border border-gray-400 rounded-md p-2 w-full" />
                    </div>

                    <div class="mb-6 bg-white py-4 px-2 rounded-md">
                        <CardCvc class="bg-white border border-gray-400 rounded-md p-2 w-full" />
                    </div>
                </div>

                <!-- Checkboxen für AGB und Datenschutz -->
                <div class="my-4">
                    <input type="checkbox" id="agbs" bind:checked={agbsAccepted} />
                    <label for="agbs" class="ml-2">Ich habe die <a href="/#/agb" class="text-blue-600 hover:underline" target="_blank">AGB</a> gelesen und akzeptiere sie.</label>
                </div>
                <div class="my-4">
                    <input type="checkbox" id="privacy" bind:checked={privacyAccepted} />
                    <label for="privacy" class="ml-2">Ich habe die <a href="/#/datenschutz" class="text-blue-600 hover:underline" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere sie.</label>
                </div>
                {#if !isPaymentEnabled}
                    <p class="text-red-600">Bitte akzeptieren Sie die AGB und die Datenschutzerklärung, um fortzufahren.</p>
                {/if}

                <!-- Fehlermeldung anzeigen -->
                {#if paymentError}
                    <p class="my-5 text-center text-red-600">{paymentError}</p>
                {/if}

                <!-- Zahlungsbutton -->
                <button class="step-ctn my-5" disabled={!isPaymentEnabled || paymentStatus === 'IN_PROGRESS'}>
                    {#if paymentStatus === 'IN_PROGRESS'}
                        Making Payment...
                    {:else}
                        Make Payment
                    {/if}
                </button>
            </form>
        </Container>
    {/if}
</div>

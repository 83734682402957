<style>
    .group {
        perspective: 1000px;
        margin: 0 16px;
    }

    .countdown {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px; /* Stellen Sie die Höhe nach Bedarf ein */
    }

    .holzrahmen {
        width: 37.5px; /* Größe des Divs */
        height: 37.5px; /* Größe des Divs */
        border: 1.5px solid #8b4513; /* Holzfarbenen Rand */
        background-color: #f4c578; /* Spezifische Hintergrundfarbe */
        display: flex; /* Flexbox-Container */
        align-items: center; /* Vertikal zentrieren */
        justify-content: center; /* Horizontal zentrieren */
    }
    .countdown-item {
        width: 37.5px;
        height: 37.5px;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 1s;
        /*transform-origin: top; */
        margin: 0 1.5px; /* Abstand zwischen den Würfeln */
        transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        position: relative;
    }

    .zahl {
        margin-top: -5px;
        display: block;
        font-size: 1.2rem; /* Angepasste Größe der Zahl */
        color: #000000; /* Farbe der Zahl */
    }

    .front {
        transform: rotateY(0deg) translateZ(18.75px);
    }
    .back {
        transform: rotateY(180deg) translateZ(18.75px);
    }
    .right {
        transform: rotateY(90deg) translateZ(18.75px);
    }
    .left {
        transform: rotateY(-90deg) translateZ(18.75px);
    }
    .top {
        transform: rotateX(90deg) translateZ(18.75px);
    }
    .bottom {
        transform: rotateX(-90deg) translateZ(18.75px);
    }

    .side {
        color: #543626;
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 37.5px;
        left: 0;
        top: 0;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        background-image: url('wood2.webp'); /* Pfad zur Holztextur */
        background-size: cover;
    }

    /* add medis query for mobile */
    @media only screen and (min-width: 768px) {
        .countdown {
            height: 200px; /* Stellen Sie die Höhe nach Bedarf ein */
        }
        .countdown-item {
            width: 75px;
            height: 75px;
            margin: 0 3px;
        }
        .holzrahmen {
            width: 75px; /* Größe des Divs */
            height: 75px; /* Größe des Divs */
            border: 5px solid #8b4513; /* Holzfarbenen Rand */
        }
        .zahl {
            margin-top: -5px;
            display: block;
            font-size: 2rem; /* Angepasste Größe der Zahl */
            color: #000000; /* Farbe der Zahl */
        }
        .front {
            transform: rotateY(0deg) translateZ(37.5px);
        }
        .back {
            transform: rotateY(180deg) translateZ(37.5px);
        }
        .right {
            transform: rotateY(90deg) translateZ(37.5px);
        }
        .left {
            transform: rotateY(-90deg) translateZ(37.5px);
        }
        .top {
            transform: rotateX(90deg) translateZ(37.5px);
        }
        .bottom {
            transform: rotateX(-90deg) translateZ(37.5px);
        }
        .side {
            line-height: 75px;
            font-size: 2rem;
        }
    }

    .back span {
        transform: rotateY(180deg) rotateX(180deg); /* Angepasst für 3D-Effekt */
    }
</style>

<script >
    import { onMount } from 'svelte'
    import { countdownExpired } from './component/stores/countdown'

    export let targetDate
    export let digital = true
    let countdown
    let previousCountdown

    let rotateDegrees = { days: 0, hours: 0, minutes: 0, seconds: 0 }
    let rotateDegreesTens = { days: 0, hours: 0, minutes: 0, seconds: 0 }

    const timeUnitTranslations = {
        days: 'Tage',
        hours: 'Std',
        minutes: 'Min',
        seconds: 'Sek'
    }
    onMount(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const distance = targetDate - now
            updateCountdown(distance)
            if (distance < 0) {
                clearInterval(interval)
                countdown = null
                previousCountdown = null
                countdownExpired.set(true)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    function updateCountdown(distance) {
        const newCountdown = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000)
        }

        for (let unit of ['days', 'hours', 'minutes', 'seconds']) {
            const tensChange = Math.floor(newCountdown[unit] / 10) !== Math.floor(countdown?.[unit] / 10)
            if (tensChange && newCountdown[unit] % 2 === 0) {
                rotateDegreesTens[unit] -= 180
            }

            if (!countdown || newCountdown[unit] !== countdown[unit]) {
                previousCountdown = { ...countdown } // Speichern des alten Werts
                rotateDegrees[unit] -= 180

                setTimeout(() => {
                    countdown[unit] = newCountdown[unit] // Aktualisieren des neuen Werts nach der Animation
                }, 1000) // Zeitdauer der Drehung
            }
        }

        countdown = { ...newCountdown }
    }
    function splitNumberIntoTensAndOnes(number) {
        const tens = Math.floor(number / 10) || 0
        const ones = number % 10 || 0
        const w = { tens, ones }
        return w
    }
    function formatDate(date) {
        return date.toLocaleString('de-DE', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    
</script>

{#if countdown}

        <h2 class="text-lg md:text-3xl font-bold text-center countdown-title">
            Shop-Eröffnung am {formatDate(new Date(targetDate))}
        </h2>
        <div class="countdown flex justify-center space-x-6">
            {#each Object.entries(countdown) as [unit, value]}
                <div class="group" key="{unit}-{Math.floor(value / 10)}">
                    <div class="text md:text-lg font-bold text-center mb-2">
                        {timeUnitTranslations[unit]}
                    </div>
                    <!-- Unit label -->
                    <div class="flex">
                        {#if !digital}
                            <div class="zehner countdown-item" style="transform: rotate3d(1, 0.00, 0, {rotateDegreesTens[unit]}deg);">
                                <div class="side front">
                                    <span>{value % 2 == 0 ? splitNumberIntoTensAndOnes(previousCountdown[unit]).tens : splitNumberIntoTensAndOnes(value).tens}</span>
                                </div>
                                <div class="side back">
                                    <span>{value % 2 != 0 ? splitNumberIntoTensAndOnes(previousCountdown[unit]).tens : splitNumberIntoTensAndOnes(value).tens}</span>
                                </div>
                                <div class="side right"></div>
                                <div class="side left"></div>
                                <div class="side top"></div>
                                <div class="side bottom"></div>
                            </div>
                            <div class="einer countdown-item" style="transform: rotate3d(1, 0.00, 0, {rotateDegrees[unit]}deg);">
                                <div class="side front">
                                    <span>{value % 2 == 0 ? splitNumberIntoTensAndOnes(previousCountdown[unit]).ones : splitNumberIntoTensAndOnes(value).ones}</span>
                                </div>
                                <div class="side back">
                                    <span>{value % 2 != 0 ? splitNumberIntoTensAndOnes(previousCountdown[unit]).ones : splitNumberIntoTensAndOnes(value).ones}</span>
                                </div>
                                <div class="side right"></div>
                                <div class="side left"></div>
                                <div class="side top"></div>
                                <div class="side bottom"></div>
                            </div>
                        {:else}
                            <div class="holzrahmen rounded-full flex items-center justify-center">
                                <span class="zahl">{value}</span>
                            </div>
                        {/if}
                    </div>
                </div>
            {/each}
        </div>
{/if}

<script lang="ts">
    import { ArticleAuthor, ArticleBody, ArticleHead, ArticleWrapper } from 'flowbite-svelte-blocks'
    import { VideoCameraSolid, ArrowRightOutline, NewspaperSolid } from 'flowbite-svelte-icons'
    import { articlesStore } from './stores/blog'

    $: articles = $articlesStore

    function daysAgo(dateString: string) {
        const today = new Date()
        const previousDate = new Date(dateString)
        const differenceInTime = today.getTime() - previousDate.getTime()
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24))
        return differenceInDays
    }

    function shortenContent(htmlContent: string, maxLines: number = 3): string {
        const div = document.createElement('div')
        div.innerHTML = htmlContent
        const textContent = div.textContent || div.innerText || ''

        const approxCharsPerLine = 100
        const maxLength = approxCharsPerLine * maxLines

        return textContent.length > maxLength ? `${textContent.substring(0, maxLength)}...` : textContent
    }
</script>

{#each articles as article}
    {#if article.published}
        <ArticleWrapper>
            <ArticleHead>
                <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                    {#if article.type === 'Tutorial'}
                        <VideoCameraSolid size="xs" class="mr-1" />
                    {:else if article.type === 'Artikel'}
                        <NewspaperSolid size="xs" class="mr-1" />
                    {/if}
                    {article.type}
                </span>
                <span class="text-sm">vor {daysAgo(article.createdAt)} Tagen erstellt</span>
            </ArticleHead>
            <ArticleBody>
                <svelte:fragment slot="h2"><a href="/#/blog/{article.slug}">{article.title}</a></svelte:fragment>
                <svelte:fragment slot="paragraph">
                    <p class="mb-5 font-light text-gray-500 dark:text-gray-400">{@html shortenContent(article.content)}</p>
                </svelte:fragment>
            </ArticleBody>
            <ArticleAuthor>
                <svelte:fragment slot="author">
                    <img class="w-7 h-7 rounded-full" src="{article.authorAvatar}" alt="Jese Leos avatar" />
                    <span class="font-medium dark:text-white"> {article.author}</span>
                </svelte:fragment>
                <a href="/#/blog/{article.slug}" class="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline">
                    Lese mehr
                    <ArrowRightOutline size="sm" class="ml-2" />
                </a>
            </ArticleAuthor>
        </ArticleWrapper>
    {/if}
{/each}

<style global lang="postcss">
    body {
        min-width: 350px;
        margin: 0; /* Entfernt den Standardrand */

        /* Hintergrund-Gradient */
        background: radial-gradient(circle at center, #f4c578 0%, #f1cd93 70%, #faf9e5 100%);
        text-align: center;
    }
    .bordered-container {
        position: relative;
        background: #faf9e5;
        z-index: 0;
    }
    .bordered-container::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 157px; /* Breite des Rahmens */
        background: url('/build/rahmen-links.png') repeat-y;
        z-index: -1;
    }

    .bordered-container::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 157px; /* Breite des Rahmens */
        background: url('/build/rahmen_rechts.png') repeat-y;
        z-index: -1;
    }

    .bordered-container::before {
        left: 0;
    }

    .bordered-container::after {
        right: 0;
    }
</style>

<script lang="ts">
    import Router from 'svelte-spa-router'
    import Home from './Home.svelte'

    import { onMount } from 'svelte'
    import Administration from './Administration.svelte'
    import { isAuthenticated } from './component/stores/authStore' // Import the store
    import Header from './component/Header.svelte'
    import AboutUs from './AboutUs.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    import Blog from './Blog.svelte'
    import Agb from './Agb.svelte'
    import Widerrufsbelehrung from './Widerrufsbelehrung.svelte'
    import BlogPost from './BlogPost.svelte'
    import Datenschutz from './Datenschutz.svelte'
    import BlogPostPreview from './BlogPostPreview.svelte'
    import Tracking from './component/Tracking.svelte'
    import Shop from './shop/routes/Shop.svelte'
    import Checkout from './shop/routes/checkout/index.svelte'
    import Payment from './shop/routes/payment/index.svelte'
    import ProductPage from './shop/routes/product/productPage.svelte'
    import { handleStoreCart } from './shop/state/cartStore'
    import { countdownExpired } from './component/stores/countdown'
    import wrap from 'svelte-spa-router/wrap'
    import { cookieConsent, loadInitialConsent } from './component/stores/cookieCheker'

    import CookieeBanner from './CookieeBanner.svelte'

    let isExpired: boolean 
  
    let isAllAccepted = false;

    $: $countdownExpired, isExpired = !!$countdownExpired;


    $: $cookieConsent, isAllAccepted = $cookieConsent.allAccepted;

    $: countdownExpired.subscribe(value => {
        isExpired = !!value;
        console.log("isExpired"+isExpired)
    })


    const routes = {
        '/': Home,
        '/admin/tracking': Tracking,
        '/ueber-uns': AboutUs,
        '/datenschutz': Datenschutz,
        '/wiederrufsbelehrung': Widerrufsbelehrung,
        '/agb': Agb,
        '/administration': Administration,
        '/blog': Blog,
        '/blog/:slug': BlogPost,
        '/blog/preview/:slug': BlogPostPreview,

        '/payment': Payment,
 
        '/shop': wrap({
            component: Shop,
            conditions: [
                () =>  isExpired
            ]
        }),
        '/checkout': wrap({
            component: Checkout,
            conditions: [
                () => isAllAccepted && isExpired 
            ]
        }),
        '/product/:productId': wrap({
            component: ProductPage,
            conditions: [
                () =>  isExpired 
            ]
        })
    }

    onMount(() => {
        loadInitialConsent()
    })
</script>

<Header />

<section class="min-h-[calc(100vh-74px)] mt-[74px] overflow-hidden md:overflow-visible bordered-container max-w-screen-lg container mx-auto py-4 px-20 md:px-32">
    <Router routes="{routes}" />

    <ImagePlacer
        img="{{
            title: 'Boden',
            description: 'Eine Bodenplate',
            url: '/build/boden.png'
        }}"
        y="{-5}"
        positionY="bottom"
        class="absolute left-1/2 bottom-0 transform -translate-x-1/2  !max-w-none !w-[120%] !lg:w-[110%] !xl:w-auto" />
</section>

<CookieeBanner />
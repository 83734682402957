<style>
    .order-summary {
        display: flex;
        flex-direction: column;
    }
</style>

<script>
    import OrderItemPreview from '../../components/orderItemPreview.svelte'
    import { medusaCartState } from '../../state/cartStore'
    import { products } from '../../state/store'
    import { formatPrice, quantity, sum } from '../../utils/helpers'

    let userProducts
    let userCart = []
    let isLoading = true

    products.subscribe(({ cartItems }) => {
        userProducts = cartItems
    })

    medusaCartState.subscribe((state) => {
        if (state.cart.items) {
            userCart = state.cart
            isLoading = false
        }
    })
</script>

{#if isLoading}
    <div>
        <p>Lade Ihre Bestellübersicht</p>
    </div>
{:else}
    <div class="order-summary">
        <div>
            <div class="flex justify-between mb-5">
                <h2 class="font-semibold text-lg">Bestellübersicht</h2>

                {userCart.items.length > 0 ? userCart?.items.map(quantity).reduce(sum) : 0}{' '}
                {userCart?.items.length > 0 && userCart?.items.map(quantity).reduce(sum) === 1 ? 'Artikel' : 'Artikel'}
            </div>
            <hr class="mb-5" />
            <div class="bottom">
                <div class="flex justify-between mb-3">
                    <p class="text-sm">Zwischensumme (inkl. Steuern)</p>
                    <p class="text-sm">{formatPrice(userCart.subtotal, userCart.region.currency_code)}</p>
                </div>

                <div class="flex justify-between mb-3">
                    <p class="text-sm">Versand</p>
                    <p class="text-sm">
                        {userCart.region ? formatPrice(userCart.shipping_total, userCart.region.currency_code) : 0}
                    </p>
                </div>

                <div class="flex justify-between mb-3">
                    <p class="text-sm font-semibold">Gesamt</p>
                    <p class="text-sm font-semibold">
                        {formatPrice(userCart.total, userCart.region.currency_code)}
                    </p>
                </div>
            </div>
        </div>
        <hr />
    </div>

    <ul class="max-h-[60vh] overflow-scroll hidden md:block">
        {#each userCart.items as { thumbnail, variant, unit_price, quantity, title }}
            <li>
                <OrderItemPreview size="{variant.title}" quantity="{quantity}" orderThumbnail="{thumbnail}" name="{title}" price="{formatPrice(unit_price, userCart.region.currency_code)}" />
            </li>
        {/each}
    </ul>
{/if}

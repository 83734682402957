<script lang="ts">
    import type { Image, Product } from '../types'
    import ProductPreviewTile from './component/ProductPreviewTile.svelte'
    import Countdown from './Countdown.svelte'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    import CarouselWrapper from './CarouselWrapper.svelte'
    import { countdownExpired } from './component/stores/countdown'

    let openingDate = new Date('Oct 01, 2024 23:59:59').getTime()

    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]

    let products: Product[] = [
        {
            name: 'Unsere Honigvielfalt',
            description: 'Mehr Erfahren über unsere Honigvielfalt',
            extendedDescription:
                'Bei uns findest du eine exquisite Auswahl einzigartiger Honigsorten, direkt aus der Natur. Jeder Tropfen stammt von wilden Blumen aus nachhaltig bewirtschafteten Feldern und bietet einen vielfältigen, reichen Geschmack, der sich in jeder Sorte widerspiegelt. Unsere Honigvielfalt ändert sich mit den Blütezeiten der Pflanzen, wodurch jeder Honig einzigartig wird. Unser 100% natürlicher Honig ist nicht nur ein Genuss für den Gaumen, sondern fördert auch nachhaltige Landwirtschaft und trägt zum Schutz der biologischen Vielfalt bei. Durch den Kauf unseres Honigs unterstützt du nachhaltige Praktiken und hilfst, die natürliche Umwelt zu bewahren.',
            image: {
                url: '/build/honig-sorten.png',
                description: 'Ein Honigtopf',
                title: ''
            }
        },
        {
            name: 'Pflegeprodukte',
            description: 'Mehr Erfahren über unsere Pflegeprodukte',
            extendedDescription:
                'Entdecken Sie bald unsere neue Pflegelinie, angereichert mit dem Besten aus der Natur und unserem wertvollen Honig. Wir bieten Seifen, Cremes, Peelings und vieles mehr, alles hergestellt mit Respekt für die Umwelt. Jedes Produkt vereint die heilenden Eigenschaften des Honigs mit natürlichen Inhaltsstoffen für eine hautverwöhnende und sinnbelebende Pflege. Unsere Kollektion ist eine Hommage an die Kraft der Natur und unser Engagement für Qualität und Nachhaltigkeit.',
            image: {
                url: '/build/pflege-produkte.png',
                description: 'Eine Collage aus Pflegeprodukten',
                title: 'Pflegeprodukte'
            }
        },
        {
            name: 'Geschenksets',
            description: 'Mehr Erfahren über unsere Geschenksets',
            extendedDescription:
                'Erleben Sie mit den exklusiven Geschenksets von uns besondere Momente. Sorgfältig zusammengestellt, beinhalten unsere Sets eine Auswahl feinster Honigprodukte für jeden Anlass. Im Frühstücksset finden Sie alles für einen süßen Start in den Tag, inklusive Honig, Met und hochwertigen Utensilien. Unser Gourmetset umfasst verschiedene Honigsorten und Met auf einem eleganten Servierbrett, ideal für Feinschmecker. Das Wellness-Set bietet natürliche Pflegeprodukte wie Honigpeeling für entspannende Momente. Jedes Set ist eine liebevoll gestaltete Hommage an Honig und Natur. ',
            image: {
                url: '/build/geschenkesets.png',
                description: 'Geschenkartikel',
                title: ''
            }
        },
        {
            name: 'Unsere Honiggesüßten Säfte - Natürlicher Genuss in jedem Schluck',
            description: 'Mehr Erfahren über unsere Honiggesüßten Säfte',
            extendedDescription:
                'Entdecken Sie die natürliche Süße unserer honiggesüßten Säfte. Jeder Schluck ist eine Mischung aus natürlicher Süße und fruchtiger Vielfalt, ohne künstliche Zusatzstoffe – nur reiner Honig und sorgfältig ausgewählte Früchte. Ideal für Frühstück, Snacks oder als erfrischendes Getränk, bieten unsere Säfte auch eine perfekte Basis für Cocktails und Mocktails. Genießen Sie den subtilen Honiggeschmack in jeder Flasche, während Sie die Umwelt unterstützen – unsere Bienenhaltung ist nachhaltig und fördert den Schutz der Bienen.',
            image: {
                url: '/build/saft.png',
                description: 'Geschenkartikel',
                title: ''
            }
        },
        {
            name: 'Honig Bier',
            description: 'Mehr Erfahren über unser Honig Bier',
            extendedDescription:
                'Entdecken Sie unser traditionell gebrautes Honigbier von Specker Bienchen, eine Kombination aus jahrhundertealter Braukunst und der natürlichen Süße des Honigs. Unsere Braumeister nutzen traditionelle Methoden, um ein einzigartiges Geschmackserlebnis zu schaffen – eine perfekte Mischung aus Malz, Hopfen und unserem eigenen Honig. Unser Sortiment bietet verschiedene Honigbiersorten für jeden Geschmack, alle nachhaltig und natürlich hergestellt. Genießen Sie unser Honigbier bei besonderen Anlässen oder im Alltag. Tauchen Sie ein in die Welt des handwerklich gebrauten Honigbiers. Prost!',
            image: {
                url: '/build/bier.png',
                description: 'Geschenkartikel',
                title: ''
            }
        },
        {
            name: 'Unser Honiglikör - Süße Verführung in Flaschen',
            description: 'Mehr Erfahren über unseren Honiglikör',
            extendedDescription:
                'Erleben Sie die süße Verführung unseres handgefertigten Honiglikörs von Specker Bienchen. Dieser exklusive Likör kombiniert die natürliche Süße unseres Honigs mit erlesenen Alkoholen und Gewürzen. Jedes Detail wird von erfahrenen Destillateuren sorgfältig beachtet, um einen unverwechselbaren Geschmack und höchste Qualität zu garantieren. Vielseitig genießbar, eignet sich unser Honiglikör pur, auf Eis oder in Cocktails und Desserts. Er ist perfekt für besondere Anlässe oder als geschmackvolles Geschenk. Nachhaltig hergestellt, repräsentiert unser Honiglikör Luxus und Genuss in einer eleganten Flasche. Genießen Sie jeden Schluck voller Luxus. Prost!',
            image: {
                url: '/build/liköre.png',
                description: 'Geschenkartikel',
                title: ''
            }
        }
    ]

    let isExpired: boolean=false

    $: countdownExpired.subscribe(value => {
        isExpired = value;
        console.log("isExpired"+isExpired)
    })
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{40}"
    y="{0}"
    class="w-1/4 md:w-auto"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    class="w-1/4 md:w-auto"
    positionX="right"
    positionY="top" />

<main class="max-w-[672px] m-auto mb-72 md:mb-72">
    <section class="my-12 max-w-[672px]">
        <IconLeftRigthWrapper>
            <h1 class="font-bold">Specker Bienchen</h1>
            <h2>Shop</h2>
            <p>Ein Webshop, der die Welt der Bienen schützt, indem er Honigprodukte verkauft und Bienenhabitate schafft.</p>
        </IconLeftRigthWrapper>
    </section>
    {#if isExpired}
        <section class="my-12 rel">
            <div class="shop-opening">
                <h2 class="text-3xl font-bold text-center">    Der Shop ist jetzt geöffnet!</h2>
                <h2 class="text-2xl font-bold text-center">    Erste Ernte verfügbar: Jetzt frischen Honig entdecken!</h2>

                <p class="subheadline text-lg text-center mt-4">
                    Die erste Ernte wurde erfolgreich abgefüllt. Weitere Produkte werden nächstes Jahr nach und nach hinzukommen. Jetzt gibt es frischen Honig im Shop!
                </p>
                <div class="cta text-center mt-8">
                    <a href="/#/shop" class="text-center font-medium focus-within:ring-4 focus-within:outline-none inline-flex items-center justify-center px-5 py-2.5 text-sm text-white bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700 focus-within:ring-green-300 dark:focus-within:ring-green-800 rounded-lg"> Zum Shop </a>
                </div>
            </div>
        </section>
    {/if}
    <section class="my-12 rel">
        <h2>News</h2>
        <CarouselWrapper />
    </section>
    <section class="my-12">
    {#if !isExpired}
        <h2>Opening</h2>
        <Countdown targetDate="{openingDate}" />
    {/if}
    </section>
    <section class="my-12">
        <h2 class="font-bold">Produktübersicht</h2>
        <p>
            Hier bei Specker Bienchen dreht sich alles um das süße Gold der Natur: Honig. Unsere Produkte sind nicht nur einzigartig, sondern auch mit Liebe und Sorgfalt hergestellt. Jedes Glas Honig
            stammt direkt aus unseren eigenen Bienenstöcken, wo wir jede Biene wie ein Familienmitglied behandeln.
        </p>
        <ProductPreviewTile products="{products}" />
    </section>

    <section class="my-12">
        <div class="mt-8">
            <h2 class="text-2xl font-semibold">Unsere Produkte und Informationen</h2>
            <p class="mt-4 text-lg">
                Unser Shop bietet nicht nur eine Auswahl an qualitativ hochwertigen Honigprodukten, sondern ist auch eine Informationsquelle für alles rund um Bienen. Wir sind bestrebt, unser Angebot
                stetig zu erweitern und unsere Seite mit spannenden Neuigkeiten und Wissenswertem zu bereichern.
            </p>

            <div class="mt-6">
                <h3 class="text-xl font-bold">Was Sie auf dieser Seite finden:</h3>
                <ul class="list-disc list-inside mt-2">
                    <li>
                        <strong>Produkte:</strong> Unsere Produktpalette umfasst hochwertige Honigprodukte, die mit Sorgfalt und Respekt vor der Natur hergestellt werden.
                    </li>
                    <li>
                        <strong>Neuigkeiten und Wissen:</strong> Bleiben Sie informiert mit aktuellen Nachrichten über Bienen und erhalten Sie Einblicke in deren faszinierende Welt. Unsere News-Sektion
                        bringt Sie auf den neuesten Stand.
                    </li>
                    <li>
                        <strong>Dynamische Inhalte:</strong> Unsere Website entwickelt sich ständig weiter. Besuchen Sie uns regelmäßig, um keine Neuheiten zu verpassen und mehr über unsere Initiativen
                        zum Schutz der Bienen zu erfahren.
                    </li>
                </ul>
            </div>

            <p class="mt-4">Wir danken Ihnen für Ihre Unterstützung auf unserer Reise zum Schutz der Bienen. Entdecken Sie mit uns die Vielfalt und Bedeutung dieser wunderbaren Geschöpfe.</p>
        </div>
    </section>
</main>

<style>
    .bg-header {
        background-image: url('/build/header-frame.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
    }
    .inner-header:before {
        content: '';
        position: absolute;
        top: 0;
        left: -55px;
        width: 55px;
        height: 72px;
        background: url('/build/header-deko-left.png');
        background-size: 55px 72px;
        z-index: 1;
    }
    .inner-header:after {
        content: '';
        position: absolute;
        top: 0;
        right: -55px;
        width: 55px;
        height: 72px;
        background: url('/build/header-deko-right.png');
        background-size: 55px 72px;
        z-index: 1;
    }

    .inner-header {
        background-image: url('/build/inner-headder.png');
        background-size: 101% 100%;
        margin-left: 55px;
        margin-right: 55px;
        width: auto;
    }

    .menu-collapsed {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition:
            opacity 0.5s ease-out,
            max-height 0.5s ease-out;
    }

    .menu-expanded {
        opacity: 1;
        max-height: 500px; /* Ersetzen Sie diesen Wert durch die maximale Höhe Ihres Menüs */
        overflow: hidden;
        transition:
            opacity 0.5s ease-in,
            max-height 0.5s ease-in;
    }

    @media (min-width: 768px) {
        .menu-collapsed,
        .menu-expanded {
            height: auto; /* Ensures the menu is always fully expanded */
            transition: none; /* Disables the transition effect */
            opacity: 1;
            max-height: 500px; /* Ersetzen Sie diesen Wert durch die maximale Höhe Ihres Menüs */
            overflow: hidden;
        }
    }
</style>

<script lang="ts">
    import { writable } from 'svelte/store'
    import Logo from './Logo.svelte'
    import Login from './form/Login.svelte'
    import Cart from '../shop/components/Cart.svelte'
    import CartView from '../shop/components/CartView.svelte'
    import { countdownExpired } from './stores/countdown'
    import { cookieConsent, showModal } from './stores/cookieCheker'
    import { handleStoreCart } from '../shop/state/cartStore'
    function openCookieBanner(){
        showModal.set(true)
    }
    let links = [
        { href: '/#', text: 'Startseite' },
        { href: '/#/blog', text: 'Blog' }
        // Fügen Sie weitere Links hinzu
    ]

    let dropdownOpen = false

    function toggleDropdown() {
        dropdownOpen = !dropdownOpen
    }
    // Reaktiver Zustand für die Anzeige der rechten Navigation
    let showRightNav = writable(false)

    // Funktion zum Umschalten des Zustands
    function toggleRightNav() {
        showRightNav.update((n) => !n)
    }
    function closeAll() {
        dropdownOpen = false
        showRightNav.set(false)
    }
    let isExpired: boolean

    // Abonniere den Store
    $: countdownExpired.subscribe((value) => {
        isExpired = !!value
    })

    let isAllAccepted = false
    $: cookieConsent.subscribe((value) => {
        console.log('cookieConsent' + value.allAccepted)
        isAllAccepted = value.allAccepted
        value.allAccepted && handleStoreCart()
    })

 
</script>

<header class="bg-amber-300 w-full bg-header z-10 fixed top-0">
    <div class="container max-w-screen-lg mx-auto !mt-0 relative">
        <div class="inner-header w-full flex items-center justify-between py-4 px-16 sm:px-28 md:px-32 lg:px-44 relative">
            <!-- Navigation auf der linken Seite -->
            <div class="hidden md:flex items-center mt-4">
                <nav class="text-black">
                    <ul class="flex justify-between list-none p-0">
                        {#each links as link}
                            <li class="mr-5 last:mr-0">
                                <!-- Fügt die Tailwind-Klassen hinzu, um die Farbe zu setzen und die Unterstreichung zu entfernen -->
                                <a on:click={closeAll} href="{link.href}" class="text-black hover:no-underline hover:text-gray-600">{link.text}</a>
                            </li>
                        {/each}
                        {#if isAllAccepted && isExpired}
                            <li class="mr-5 last:mr-0">
                                <!-- Fügt die Tailwind-Klassen hinzu, um die Farbe zu setzen und die Unterstreichung zu entfernen -->
                                <a on:click={closeAll} href="/#/shop" class="text-black hover:no-underline hover:text-gray-600">Shop</a>
                            </li>
                        {/if}
                    </ul>
                </nav>
            </div>

            <!-- Navigation Logo -->
            <div class="left-0 md:absolute md:left-1/2 md:transform md:-translate-x-1/2 top-0 z-20">
                <Logo />
            </div>

            <!-- Navigation auf der rechten Seite -->
            <nav class="header-right flex items-center mt-4">
                <button class="md:hidden relative z-50" on:click="{toggleRightNav}">☰</button>
                <!-- Toggle-Button für die rechte Navigation -->
                <ul
                    class="text-left absolute md:static bg-[#fef3d0] md:bg-transparent left-0 w-full md:w-auto z-10 top-[72px] flex flex-col md:flex md:flex-row justify-between list-none p-0 overflow-hidden {$showRightNav
                        ? 'menu-expanded'
                        : 'menu-collapsed'} md:flex">
                    {#each links as link}
                        <li class="p-2 md:p-0 mr-0 md:mr-5 last:mr-0 flex md:hidden border-b border-[#895126] md:border-none">
                            <a href="{link.href}" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">{link.text}</a>
                        </li>
                    {/each}
                    <!-- <li
                        class="p-2 md:p-0 mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126] md:border-none">
                        <Login />
                    </li> -->
                    {#if isAllAccepted && isExpired}
                        <li class="p-2 md:p-0 mr-0 md:mr-5 last:mr-0 flex md:hidden border-b border-[#895126] md:border-none">
                            <!-- Fügt die Tailwind-Klassen hinzu, um die Farbe zu setzen und die Unterstreichung zu entfernen -->
                            <a href="/#/shop" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">Shop</a>
                        </li>
                    {/if}

                    <li class="flex flex-col p-2 md:p-0 mr-0 md:mr-5 last:mr-0  border-b border-[#895126] md:border-none">
                        <!-- Button für Dropdown -->
                        <button
                            id="dropdownNavbarLink"
                            on:click="{toggleDropdown}"
                            class="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto ">
                            Rechtliches
                            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"></path>
                            </svg>
                        </button>
                        {#if dropdownOpen}
                            <div id="dropdownNavbar" class="md:absolute md:top-[72px] z-10 md:bg-[#fef3d0] md:w-44 ">
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400 pl-4 md:pl-0">
                                    <li class="p-2  mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126]">
                                        <a href="/#/ueber-uns" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">Über uns</a>
                                    </li>
                                    <li class="p-2  mr-0 md:mr-5 last:mr-0 flex  border-b border-[#895126] ">
                                        <a href="/#/datenschutz" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">Datenschutz</a>
                                    </li>
                                     <li class="p-2  mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126]">
                                        <a href="/#/agb" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">Agb's</a>
                                    </li>
                                    <li class="p-2  mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126]">
                                        <a href="/#/wiederrufsbelehrung" on:click={closeAll} class="text-black hover:no-underline hover:text-gray-600">Widerrufsbelehrung</a>
                                    </li>
                                    <li class="p-2 mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126]">
                                        <a href="#/cookiehandler" on:click|preventDefault={() => {  openCookieBanner(); closeAll() }} class="text-black hover:no-underline hover:text-gray-600">Cookie-Einstellungen ändern</a>
                                    </li>                                    
                                </ul>
                            </div>
                        {/if}
                    </li>
                    <li class="p-2 md:p-0 mr-0 md:mr-5 last:mr-0 flex border-b border-[#895126] md:border-none">
                        <Cart bind:dropdownOpen bind:showRightNav/>
                    </li>
                </ul>
            </nav>
        </div>

        {#if isExpired}
            <CartView />
        {:else}
            <p>Der Countdown läuft noch! Zeige normale Inhalte an.</p>
        {/if}
    </div>
</header>

<script>
    export let activeStage

    const stages = ['Information', ' / ', 'Delivery', ' / ', 'Payment']
    const stageMap = {
        Information: 'Informationen',
        ' / ': ' / ',
        Delivery: 'Lieferung',
        Payment: 'Bezahlung'
    }
</script>

<div class="flex">
    {#each stages as stage}
        <p class="mx-1" style="{`color: ${activeStage === stage ? 'black' : 'grey'}`}">
            {stageMap[stage]}
        </p>
    {/each}
</div>

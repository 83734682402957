<script lang="ts">
    import type { Image, Product } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{20}"
    y="{0}"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    positionX="right"
    positionY="top" />

    <main class="max-w-[672px] m-auto mb-96">
        <section class="my-12 max-w-[672px]">
            <IconLeftRigthWrapper>
                <h1 class="text-2xl font-bold text-center mb-4">Allgemeine Geschäftsbedingungen (AGB)</h1>
            </IconLeftRigthWrapper>
    
            <div class="shadow-md rounded-lg p-6 mt-12 mx-4 md:mx-28">
                <h2 class="text-xl font-semibold mb-2">1. Geltungsbereich</h2>
                <p class="mb-4">
                    Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Bestellungen, die über unsere Website <a href="http://www.specker-bienchen.de" class="text-blue-600 hover:text-blue-800 visited:text-purple-600">www.specker-bienchen.de</a> durch Verbraucher getätigt werden.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">2. Vertragspartner</h2>
                <p class="mb-4">
                    Der Kaufvertrag kommt zustande mit:  
                    <br>
                    Sabrina & Alexander Feier  
                    <br>
                    Auf den Stöcken 52, 41472 Neuss
                </p>
    
                <h2 class="text-xl font-semibold mb-2">3. Vertragsschluss</h2>
                <p class="mb-4">
                    Die Darstellung der Produkte auf unserer Website stellt kein rechtlich bindendes Angebot dar, sondern eine unverbindliche Aufforderung zur Bestellung. Sie können unsere Produkte unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren.
                    <br>
                    Der Vertrag kommt zustande, indem Sie durch Anklicken des Bestellbuttons das Angebot über die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten Sie eine Bestätigungs-E-Mail.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">4. Preise und Versandkosten</h2>
                <p class="mb-4">
                    Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und sonstige Preisbestandteile. Da wir als Kleinunternehmer nach § 19 UStG agieren, wird keine Mehrwertsteuer ausgewiesen.
                    <br>
                    Zusätzlich zu den angegebenen Preisen berechnen wir Versandkosten. Diese werden Ihnen vor Abgabe Ihrer verbindlichen Bestellung deutlich mitgeteilt.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">5. Zahlung</h2>
                <p class="mb-4">
                    Die Zahlung erfolgt wahlweise per Überweisung, PayPal oder über den Zahlungsdienstleister Stripe.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">6. Lieferung</h2>
                <p class="mb-4">
                    Lieferungen erfolgen ausschließlich innerhalb Deutschlands. Die Lieferzeit beträgt, sofern nicht beim Angebot anders angegeben, ca. 5-7 Werktage.
                    <br>
                    Sollte ein Produkt nicht verfügbar sein, werden wir Sie umgehend informieren und eventuell bereits erhaltene Gegenleistungen unverzüglich zurückerstatten.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">7. Eigentumsvorbehalt</h2>
                <p class="mb-4">
                    Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">8. Widerrufsrecht</h2>
                <p class="mb-4">
                    Verbraucher haben ein vierzehntägiges Widerrufsrecht. Weitere Informationen zum Widerrufsrecht finden Sie in unserer <a href="/#/widerrufsbelehrung" class="text-blue-600 hover:text-blue-800 visited:text-purple-600">Widerrufsbelehrung</a>.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">9. Gewährleistung</h2>
                <p class="mb-4">
                    Es gilt das gesetzliche Mängelhaftungsrecht.
                </p>
    
                <h2 class="text-xl font-semibold mb-2">10. Streitbeilegung</h2>
                <p class="mb-4">
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: <a href="https://ec.europa.eu/consumers/odr" class="text-blue-600 hover:text-blue-800 visited:text-purple-600">https://ec.europa.eu/consumers/odr</a>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
                </p>
            </div>
        </section>
    </main>
    
    
    
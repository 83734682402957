<style>
    .image {
        position: absolute;
        transition: transform 0.3s ease;
        z-index: 100;
    }
</style>

<!-- ImagePlacer.svelte -->
<script lang="ts">
    import type { Image } from '../../types'

    export let img: Image // Bildquelle
    export let x: null | number = null // X-Koordinate
    export let y: null | number = null // Y-Koordinate
    export let positionX: null | string = null // 'left' oder 'right'
    export let positionY: null | string = null // 'top' oder 'bottom'

    let className: string | undefined = undefined
    let idName: string | undefined = undefined
    export { className as class }
    export { idName as id }
</script>

<!-- Anwendung der positionX und positionY Props für die Positionierung -->
<img
    src="{img.url}"
    alt="{img.description}"
    id="{idName}"
    class="image {className}"
    style="{positionX ? positionX + ':' : ''}{positionX && (x === 0 || y !== null) ? x + 'px;' : ''} {positionY ? positionY + ':' : ''}{(positionY && y === 0) || y !== null ? y + 'px;' : ''}  " />

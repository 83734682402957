<style>
    .headline-container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .bild {
        width: 100px; /* Oder eine andere passende Größe */
        height: auto;
    }

    .headline {
        margin: 0 20px; /* Abstand zwischen Bildern und Überschrift */
    }
</style>

<script>
    // Externe Props für die Bilder
    export let bildLinks = '/build/biene_headline.png'
    export let bildRechts = '/build/headline_rigth.png'
</script>

<div class="headline-container">
    <img src="{bildLinks}" alt="Bild links" class="bild hidden md:block" />
    <div class="headline">
        <slot />
    </div>
    <img src="{bildRechts}" alt="Bild rechts" class="bild hidden md:block" />
</div>

export const formatPrice = (price, currency) => {
    return `${(price / 100).toFixed(2)} ${currency && currency.toUpperCase()}`
}

export const quantity = (item) => {
    return item.quantity
}

export const sum = (prev, next) => {
    return prev + next
}

export const getRouteParam = (url, itemKey) => {
    const param = url.split('/')

    return param[itemKey]
}

function getTaxRate(cart) {
    if ('tax_rate' in cart) {
        return cart.tax_rate / 100
    } else if (cart.region) {
        return cart.region && cart.region.tax_rate / 100
    }
    return 0
}

export function formatMoneyAmount(moneyAmount, digits, taxRate = 0) {
    let locale = 'de-DE'; // TODO dynamic Default locale to "de-DE" (German, Germany)

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: moneyAmount.currencyCode,
        minimumFractionDigits: digits
    }).format(moneyAmount.amount * (1 + taxRate / 100))
}

export function getVariantPrice(cart, variant) {
    let taxRate = getTaxRate(cart) || 0; // Default tax rate to 0 if not found

    // Find price matching the currency code
    let moneyAmount = variant.prices.find(p => p.currency_code.toLowerCase() === cart?.region?.currency_code?.toLowerCase() || 'eur');

    // If valid price found, calculate final price including tax
    if (moneyAmount && moneyAmount.amount) {
        return (moneyAmount.amount * (1 + taxRate)) / 100;
    }

    // Return undefined if no valid price is found
    return undefined;
}

export function formatPrices(cart, variant, digits = 2) {
    // Return early if cart, variant, prices, or region data is missing
    if (!cart || !variant || !variant.prices ) {
        return "Price not available";
    }

    const amount = getVariantPrice(cart, variant); // Get price from variant
    if (amount === undefined || amount === 0) {
        return "Price not available"; // Better handling if no valid price found
    }

    const currencyCode = cart?.region?.currency_code || "EUR"; // Default to "EUR" if not present

    return formatMoneyAmount({ currencyCode, amount }, digits); // Format final price
}

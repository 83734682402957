// src/stores/cookieConsentStore.js
import { writable } from 'svelte/store';

export const cookieConsent = writable({
    allAccepted: false,
    necessaryAccepted: false,

});
export const showModal = writable(
    false 
);

export function loadInitialConsent() {
    const allConsent = localStorage.getItem('allConsent') === 'true';
    const necessaryConsent = localStorage.getItem('necessaryConsent') === 'true';

    cookieConsent.set({
        allAccepted: allConsent,
        necessaryAccepted: necessaryConsent
    });

}

export function acceptAll() {
    cookieConsent.update(current => {
        const updated = { ...current, allAccepted: true, necessaryAccepted: true };
        localStorage.setItem('allConsent', 'true');
        localStorage.setItem('necessaryConsent', 'true');
 
        return updated;
    });
}

export function acceptNecessary() {
    cookieConsent.update(current => {
        const updated = { ...current, necessaryAccepted: true, allAccepted: false };
        localStorage.setItem('necessaryConsent', 'true');
        localStorage.setItem('allConsent', 'false');
        return updated;
    });
}


<style>
    .step-ctn {
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        box-shadow: 0 2px 3px #c4c4c4;
        padding: 1rem;
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    .custom-btn {
        background-color: #007bff;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .custom-btn:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
</style>

<script>
    import { onMount } from 'svelte';
    import PaymentCard from '../../components/payment-card.svelte';
    import { medusaPayment, setPaymentSession, startPaymentSession } from '../../state/productStore';

    let userPayment = [];
    let paymentLoader = true;
    let selectedPaymentMethod = null; // Speichert die ausgewählte Zahlungsmethode

    // Checkboxen für AGB und Datenschutz
    let agbsAccepted = false;
    let privacyAccepted = false;

    // Kontrolliert, ob beide Checkboxen akzeptiert wurden
    let isPaymentEnabled = false;

    onMount(async () => {
        await startPaymentSession();
    });

    medusaPayment.subscribe((state) => {
        if (state.cart) {
            userPayment = state.cart;
            paymentLoader = false;
        }
    });

    const handlePayment = async (providerId) => {
        try {
            await setPaymentSession(providerId);
            if (typeof window !== 'undefined') {
                window.location.href = '/#/payment';
            }
        } catch (e) {
            console.log(e);
        }
    };

    const showPayment = (providerId) => {
        selectedPaymentMethod = providerId; // Speichert die ID der gewählten Zahlungsmethode
    };

    const paymentMethods = {
        manual: "Barzahlung oder Überweisung",
        stripe: "Credit Karte"
    };

    // Aktualisiert den Zustand, ob der Bezahlen-Button aktiviert ist
    $: isPaymentEnabled = agbsAccepted && privacyAccepted;
</script>

<div>
    {#if paymentLoader}
        <p>Lade Zahlvorgang</p>
    {:else}
        <h2 class="font-semibold text-2xl mb-5 mt-5">Zahlung</h2>

        <!-- Erste Schleife für die Auswahl der Zahlungsmethoden -->
        <div>
            {#each userPayment.payment_sessions as session}
                <div
                    role="button"
                    tabindex="0"
                    on:keydown="{(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            event.preventDefault();
                            showPayment(session.provider_id);
                        }
                    }}"
                    on:click="{() => showPayment(session.provider_id)}"
                    class="step-ctn cursor-pointer"
                    style="border: {selectedPaymentMethod === session.provider_id ? '2px solid #383e46' : '1px solid #ccc'}; padding: 10px;">
                    <div class="flex justify-between">
                        <p>{ paymentMethods?.[session?.provider_id]}</p>
                    </div>
                </div>
            {/each}
        </div>

        <!-- Zweite Schleife für die Anzeige der gewählten Zahlungsmethode -->
        <div>
            {#each userPayment.payment_sessions as session}
                {#if selectedPaymentMethod === session.provider_id}
                    {#if session.provider_id === 'manual'}
                        <div class="mb-6">
                            <h1 class="font-semibold text-2xl">Rechtliches</h1>
                       <!-- Checkboxen für AGB und Datenschutz -->
                       <div class="my-4">
                        <input type="checkbox" id="agbs" bind:checked={agbsAccepted} />
                        <label for="agbs" class="ml-2">Ich habe die <a href="/#/agb" class="text-blue-600 hover:underline" target="_blank">AGB</a> gelesen und akzeptiere sie.</label>
                    </div>
                    <div class="my-4">
                        <input type="checkbox" id="privacy" bind:checked={privacyAccepted} />
                        <label for="privacy" class="ml-2">Ich habe die <a href="/#/datenschutz" class="text-blue-600 hover:underline" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere sie.</label>
                    </div>

                    <!-- Fehlermeldung anzeigen -->
                    {#if !isPaymentEnabled}
                        <p class="text-red-600">Bitte akzeptieren Sie die AGB und die Datenschutzerklärung, um fortzufahren.</p>
                    {/if}
                            <div style="text-align: right;">
                                <button on:click="{() => handlePayment(session.provider_id)}" class="step-ctn" disabled={!isPaymentEnabled}>Bezahlen</button>
                            </div>
                        </div>
                    {:else}
                        <PaymentCard session="{session}" />
                    {/if}
                {/if}
            {/each}
        </div>
    {/if}
</div>

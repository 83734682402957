<style>
    .miror-frame {
        background-size: 100%;
        background-position: center;
    }
</style>

<script>
    export let frameImageURL = '/build/frame-mirrow.png' // Pfad zum Rahmenbild
    export let logoImageURL = '/build/logo.png' // Pfad zum Logobild
</script>

<!-- Das Logo wird so positioniert, dass es am oberen Rand der Navigation anfängt -->
<div
    class="miror-frame overflow-hidden w-20 h-20 md:w-24 md:h-24 rounded-full shadow-inner-frame absolute left-0 top-0 md:relative flex items-center justify-center"
    style="background-image: url({frameImageURL}); background-size: 80; ">
    {#if logoImageURL}
        <img src="{logoImageURL}" alt="Logo" class="w-4/5 h-4/5 rounded-full" style="object-fit: cover;" />
    {/if}
</div>

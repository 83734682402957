<script lang="ts">
    import type { Image, Product } from '../types'
    import RandomImages from './component/RandomImages.svelte'
    import IconLeftRigthWrapper from './component/IconLeftRigthWrapper.svelte'
    import ImagePlacer from './component/ImagePlacer.svelte'
    let leftImage: Image[] = [
        {
            title: 'Imker',
            description: 'Ein Imker mit einem Bienenstock',
            url: '/build/randmomimages/holz_imker.png'
        },
        {
            title: 'Honigtopf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Sieb',
            description: 'Ein Sieb mit Honig',
            url: '/build/randmomimages/sieb-kübel.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_imker.png'
        }
    ]
    let rightImage: Image[] = [
        {
            title: 'Honig Topf',
            description: 'Ein Honigtopf',
            url: '/build/randmomimages/honig-topf.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_gr.png'
        },
        {
            title: 'Bienen Teller',
            description: 'Ein Teller mit Bienen',
            url: '/build/randmomimages/teller_biene_haus.png'
        },
        {
            title: 'Blumen Teller',
            description: 'Ein Teller mit Blumen',
            url: '/build/randmomimages/teller_blumen.png'
        },
        {
            title: 'Teller',
            description: 'Ein Teller mit Honig',
            url: '/build/randmomimages/teller_v3.png'
        }
    ]
</script>

<RandomImages leftImages="{leftImage}" rightImages="{rightImage}" />
<ImagePlacer
    img="{{
        title: 'Imker Symbole',
        description: 'Einige imker Symbole',
        url: '/build/inner-content-grafik-left.png'
    }}"
    x="{20}"
    y="{0}"
    positionX="left"
    positionY="top" />
<ImagePlacer
    img="{{
        title: 'Biene',
        description: 'Eine Biene',
        url: '/build/inner-content-grafik-right.png'
    }}"
    x="{50}"
    y="{0}"
    positionX="right"
    positionY="top" />

<main class="max-w-[672px] m-auto mb-96">
    <section class="my-12 max-w-[672px]">
        <IconLeftRigthWrapper>
            <h1 class="text-2xl font-bold text-center mb-4">Über Uns / Impressum</h1>
        </IconLeftRigthWrapper>

        <div class=" shadow-md rounded-lg p-6 mt-12 mx-4 md:mx-28">
            <h2 class="text-xl font-semibold mb-2">Kontakt</h2>
            <p class="mb-4">
                <strong>Inhaber:</strong> Sabrina & Alexander Feier
            </p>
            <p class="mb-4">
                <strong>Adresse:</strong> Auf den Stöcken 52, 41472 Neuss
            </p>
            <p class="mb-4">
                <strong>Website:</strong>
                <a href="http://www.specker-bienchen.de" class="text-blue-600 hover:text-blue-800 visited:text-purple-600">www.specker-bienchen.de</a>
            </p>
        </div>
    </section>
</main>

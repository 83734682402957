import { writable } from 'svelte/store';

function getQueryParam(param: string) {
    const params = new URLSearchParams(window.location.search);
    
    // Überprüfen, ob der Parameter in der URL existiert
    if (params.has(param)) {
        console.log("has param", params.get(param));
        // Gibt 'true' zurück, wenn der Parameter auf 'true' gesetzt ist, sonst 'false'
        return params.get(param)?.replaceAll('/','') === 'true';
    }
    
    // Wenn der Parameter nicht existiert, geben wir `null` zurück
    return null;
}

// Initialisieren des Stores: Wenn 'ab-testing' vorhanden ist, wird es ausgewertet, andernfalls auf Entwicklungsmodus prüfen
export const countdownExpired = writable(
    getQueryParam('ab-testing') !== null ? getQueryParam('ab-testing') : import.meta.env.MODE === 'development'
);
